"use client";

/**
 * @Author: JulienBigot7 julien@generation-internet.biz
 * @Date: 2024-11-20 11:45:38
 * @LastEditors: JulienBigot7 julien@generation-internet.biz
 * @LastEditTime: 2024-12-03 15:11:19
 * @FilePath: src/app/(main)/_public_components/offer-card.tsx
 * @Description: 这是默认设置,可以在设置》工具》File Description中进行配置
 */
import { AnimatePresence, motion } from "framer-motion";
import React, { ReactNode, useState } from "react";
import { twMerge } from "tailwind-merge";
import SkewedBackground from "@/app/(main)/_public_components/skewed-background";
import { ContractClubMemberPaymentEnum } from "@/TS/Enums/global_enums";
import { OfferCardInfoProps, OfferCardProps } from "@/TS/Interfaces/global_interfaces";
function OfferCard({
  title,
  offerPrice,
  renderOfferInfo,
  offerInfoComponent,
  label,
  offerList,
  containerClassName,
  isFoundingMember
}: OfferCardProps<OfferCardInfoProps>) {
  const [selectedPrice, setSelectedPrice] = useState(offerPrice);
  return <div className={twMerge("bg-dark-blue min-w-60 md:min-w-96 font-sora h-[400px] p-2 rounded-xl pb-8 flex flex-col justify-between", containerClassName)} data-sentry-component="OfferCard" data-sentry-source-file="offer-card.tsx">
      <h3 className={"p-4 mb-0 text-secondary font-sora text-center text-2xl font-bold bg-white rounded-t-lg"}>
        {title}
      </h3>
      <div className="flex flex-col">
        <div className="flex gap-2 flex-col items-center text-white">
          {renderOfferInfo ? renderOfferInfo({
          optionsList: [{
            label: "Mensuel",
            value: ContractClubMemberPaymentEnum.DEFAULT_MONTHLY_AMOUNT
          }, {
            label: "Annuel",
            value: ContractClubMemberPaymentEnum.DEFAULT_YEARLY_AMOUNT
          }],
          setSelectedPrice: setSelectedPrice
        }) : offerInfoComponent}
          <div className="flex gap-2">
            <AnimatePresence mode="wait" data-sentry-element="AnimatePresence" data-sentry-source-file="offer-card.tsx">
              <motion.p className={"text-4xl font-bold"} key={selectedPrice || offerPrice} initial={{
              opacity: 0,
              y: -10
            }} animate={{
              opacity: 1,
              y: 0
            }} exit={{
              opacity: 0,
              y: 10
            }} transition={{
              duration: 0.2
            }} data-sentry-element="unknown" data-sentry-source-file="offer-card.tsx">
                {selectedPrice ? selectedPrice : offerPrice} €
              </motion.p>
            </AnimatePresence>
            <AnimatePresence mode="wait" data-sentry-element="AnimatePresence" data-sentry-source-file="offer-card.tsx">
              <motion.p key={selectedPrice || offerPrice} initial={{
              opacity: 0
            }} animate={{
              opacity: 1
            }} exit={{
              opacity: 0
            }} transition={{
              duration: 0.2
            }} data-sentry-element="unknown" data-sentry-source-file="offer-card.tsx">
                {isFoundingMember ? "HT" : selectedPrice === 80 ? "HT/mois" : "HT/an"}
              </motion.p>
            </AnimatePresence>
          </div>
          <SkewedBackground className={"mx-1 text-dark-blue text-nowrap"} data-sentry-element="SkewedBackground" data-sentry-source-file="offer-card.tsx">
            <b className={"text-wrap min-w-fit"}>par membre</b>
          </SkewedBackground>
        </div>
      </div>
      <div className="flex flex-col ">
        <div className={"h-[2px] rounded-full bg-white flex mx-4"} />
        <ul className={"mx-8 mt-4"}>
          {offerList.map(offer => {
          if (offer === "") {
            return <li className={"opacity-0  text-sm"}>easter egg</li>;
          }
          return <li className={"list-disc  text-sm text-white"}>{offer}</li>;
        })}
        </ul>
      </div>
    </div>;
}
export default OfferCard;